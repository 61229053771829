import { useState } from "react";
import API from "../../../api";

const useDeleteSubscriber = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriberID, setSubscriberID] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const openModal = (id: string) => {
    setSubscriberID(id);
    console.log({subscriberID})
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSubscriberID(null);
  };

  const deleteSubscriber = async (
    onSuccess: () => void,
    onFailure: (error: string) => void
  ) => {
    if (subscriberID) {
      const url = `/subscribers/${subscriberID}`;
      setIsDeleting(true);
      try {
        await API.delete(url);
        onSuccess();
      } catch (error) {
        onFailure(`${error}`);
      } finally {
        closeModal();
        setIsDeleting(false);
      }
    }
  };

  return { isModalOpen, openModal, closeModal, deleteSubscriber, isDeleting };
};

export default useDeleteSubscriber;
