import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../../../api";

interface EditFormInputType {
    Name: string;
    Email: string;
    Tags?: string,
    Affiliation?: string,
    Designation?: string,
}



const useUpdateSubscriber = () => {
    const [toast, setToast] = useState({ show: false, message: "", type: "success" });

    const [inputValues, setInputValues] = useState<EditFormInputType>({
        Name: "",
        Email: "",
        Tags: "",
        Affiliation: "",
        Designation: "",
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriberID, setSubscriberID] = useState<string>('');
    const [isUpdating, setIsUpdating] = useState<boolean>(false);



    const fetchSubscriberDetails = async (subscriberID: string) => {
        const response = await API.get(`/subscribers/details/${subscriberID}`);
        const subscriberDetails = response.data.payload;
        const { Name, Email, Tags, Affiliation, Designation } = subscriberDetails;
        console.log({ Name, Email, Tags, Affiliation, Designation })
        setInputValues({
            Name: subscriberDetails.Name || "",
            Email: subscriberDetails.Email || "",
            Tags: subscriberDetails.Tags || "",
            Affiliation: subscriberDetails.Affiliation || "",
            Designation: subscriberDetails.Designation || "",
        })
        console.log(" fetch subscriber Detail subscriberDetail", response.data.payload);
        if (!response.data.success) {
            throw new Error(response.data.error || "Failed to fetch subscriber details");
        }
        return response.data.payload;
    };

    const updateSubscriberDetails = async ({ subscriberID, updatedData }: { subscriberID: string; updatedData: Record<string, any> }) => {
        const response = await API.patch(`/public/subscriber/${subscriberID}`, updatedData);

        if (!response.data.success) {
            throw new Error(response.data.error || "Failed to update subscriber details");
        }
        return response.data.payload;
    };


    const handleInputChange = (id: string, value: string) => {
        console.log({ id, value, inputValues });
        setInputValues((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const queryClient = useQueryClient();

    // Query to fetch subscriber details
    const { data: subscriberDetails, isLoading: isFetchingDetails, refetch } = useQuery(
        ["subscriberDetails", subscriberID],
        () => fetchSubscriberDetails(subscriberID as string),
        {
            enabled: subscriberID !== '' ? true : false, // Fetch only when subscriberID is available
            initialData: null, // Initial state
        }
    );


    // Mutation for updating subscriber details
    const mutation = useMutation(updateSubscriberDetails, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["subscriberDetails", subscriberID]); // Refresh data
            queryClient.invalidateQueries(["subscribers"]);
            console.log("Update successful:", data);
        },
        onError: (error) => {
            console.error("Update failed:", error);
        },
    });

    const openModal = (id: string) => {
        console.log({ id });
        setSubscriberID(id);
        console.log({ subscriberID });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSubscriberID('');
        queryClient.removeQueries(["subscriberDetails", subscriberID]); // Clear the cache
    };

    const updateSubscriber = async (
        { subscriberID, data }: { subscriberID: string; data: Record<string, any> },
        onSuccess: () => void,
        onFailure: (error: string) => void
    ) => {
        if (subscriberID) {
            setIsUpdating(true);
            try {
                console.log("Updating with data:", data); // Debugging log
                await mutation.mutateAsync({ subscriberID, updatedData: data });
                console.log("Update complete"); // Debugging log
                onSuccess();
            } catch (error) {
                console.error("Update error:", error); // Debugging log
                onFailure(`${error}`);
            } finally {
                setIsUpdating(false);
            }
        } else {
            console.warn("No subscriber ID provided for update"); // Debugging log
        }
    };



    return {
        toast,
        setToast,
        isModalOpen,
        openModal,
        closeModal,
        updateSubscriber,
        isUpdating,
        subscriberDetails,
        isFetchingDetails,
        inputValues,
        fetchSubscriberDetails,
        handleInputChange,
        subscriberID,
        refetch,
    };
};

export default useUpdateSubscriber;
