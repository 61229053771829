import { Button, Label, Modal, TextInput, Toast } from "flowbite-react";
import { useEffect, useState } from "react";
import useUpdateSubscriber from "../../pages/Subscriber/hooks/useUpdateSubscriber";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";

interface EditSubscriberModalProps {
    openModal: boolean;
    closeOpenModel: () => void;
    subscriberID: string; // Pass the subscriber ID to fetch details
}

export default function EditSubscriberModal({
    openModal,
    closeOpenModel,
    subscriberID
}: EditSubscriberModalProps) {
    const {
        inputValues,
        handleInputChange,
        updateSubscriber,
        isUpdating,
        fetchSubscriberDetails,
        setToast
    } = useUpdateSubscriber();

  
   
    const { data, isLoading: isFetchingDetails } = useQuery(
        ["subscriberDetails", subscriberID],
        () => fetchSubscriberDetails(subscriberID as string),
        {
            enabled: subscriberID !== "" ? true : false, // Fetch only when subscriberID is available
            initialData: null // Initial state
        }
    );

    useEffect(() => {
        console.log("when modal was called", { inputValues }, subscriberID);
    }, [subscriberID]);

    const handleSave = () => {
        console.log("calling from handle save", inputValues);

        updateSubscriber(
            { subscriberID, data: inputValues },
            () => {
                setToast({ show: true, message: "Subscriber updated successfully!", type: "success" });
                closeOpenModel();
            },
            (error) => {
                setToast({ show: true, message: `Failed to update subscriber: ${error}`, type: "error" });
            }
        );
    };

    return (
        <>
            <Modal
                show={openModal}
                size="xl"
                onClose={() => closeOpenModel()}
                popup
            >
                <Modal.Header />
                <Modal.Body>
                    {isFetchingDetails ? (
                        <>Fetching Details</>
                    ) : (
                        <div className="grid grid-cols-12 gap-4">
                            {/* Name Input */}
                            <div className="col-span-6 flex flex-col">
                                <Label htmlFor="Name" value="Name" />
                                <TextInput
                                    id="Name"
                                    type="text"
                                    placeholder="Enter name here"
                                    value={inputValues.Name}
                                    onChange={(e) => handleInputChange("Name", e.target.value)}
                                />
                            </div>
                            {/* Email Input */}
                            <div className="col-span-6 flex flex-col">
                                <Label htmlFor="Email" value="Email" />
                                <TextInput
                                    id="Email"
                                    type="text"
                                    placeholder="Enter email suffix here"
                                    value={inputValues.Email}
                                    onChange={(e) => handleInputChange("Email", e.target.value)}
                                />
                            </div>
                            {/* Tags Input */}
                            <div className="col-span-6 flex flex-col">
                                <Label htmlFor="Tags" value="Tags" />
                                <TextInput
                                    id="Tags"
                                    type="text"
                                    placeholder="Enter tags here"
                                    value={inputValues.Tags}
                                    onChange={(e) => handleInputChange("Tags", e.target.value)}
                                />
                            </div>
                            {/* Affiliation Input */}
                            <div className="col-span-6 flex flex-col">
                                <Label htmlFor="Affiliation" value="Affiliation" />
                                <TextInput
                                    id="Affiliation"
                                    type="text"
                                    placeholder="Enter affiliation here"
                                    value={inputValues.Affiliation || ""}
                                    onChange={(e) => handleInputChange("Affiliation", e.target.value)}
                                />
                            </div>
                            {/* Designation Input */}
                            <div className="col-span-6 flex flex-col">
                                <Label htmlFor="Designation" value="Designation" />
                                <TextInput
                                    id="Designation"
                                    type="text"
                                    placeholder="Enter designation here"
                                    value={inputValues.Designation || ""}
                                    onChange={(e) => handleInputChange("Designation", e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} disabled={isUpdating}>
                        {isUpdating ? "Saving..." : "Save"}
                    </Button>
                    <Button color="gray" onClick={() => closeOpenModel()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

         
        </>
    );
}
