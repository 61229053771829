import axios from "axios";

export const BASE_URL = `${window.location.protocol}//${window.location.host}/server/blast_function`;
//export const BASE_URL = `https://blast-759987269.catalystserverless.com/server/blast_function`;
const API = axios.create({
  baseURL: BASE_URL,
});
// export const BASE_URL2=`https://blast-759987269.catalystserverless.com/server/blast_function/`;
// const API2 = axios.create({
//   baseURL:BASE_URL2,
//   headers:{
//     cookie:"_iamadt_client_10070825017=9c44a633ce742adcce76dd24298799180714bcc3be394b93413a4599272247f5918626d00404e90e5152b606fbe5797d070bde47c06f198ffeefe3ec0fe92122; _iambdt_client_10070825017=46054c44942b5c435ee1f7f2b531c59bad31b32701cc550f5773d5d91640bf4d5a760c43a780935c8428f2ce33447cebc4238ca5627925afcdb611c8d17b91ab; _z_identity=true; zalb_46512fd555=4ead9897700827f973c0f7dbded7f673; ZD_CSRF_TOKEN=2d9aba2c7920ff811ad9c09e198ca31b82edd260313f509bcdd876d96acee058f99065f11daa4a02e4552f27709b8eb2dc2e88a96ed2498707dcd7268c301170; _zcsr_tmp=2d9aba2c7920ff811ad9c09e198ca31b82edd260313f509bcdd876d96acee058f99065f11daa4a02e4552f27709b8eb2dc2e88a96ed2498707dcd7268c301170",
//     host:"releaseblast.com"
//   }
// })

// export {API2};

export default API;
