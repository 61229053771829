import API from '../../api';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'flowbite-react';
import React, { Dispatch, SetStateAction } from 'react';
// Define the Segment type based on API response
interface Segment {
    CREATORID: string;
    emailEndsWith: string | null;
    affiliationlogic: string;
    creator: string | null;
    emaillogic: string;
    segmentName: string;
    tags: string | null;
    MODIFIEDTIME: string;
    createdDate: string | null;
    affiliation: string | null;
    name: string;
    CREATEDTIME: string;
    designation: string | null;
    tagslogic: string;
    ROWID: string;
}
interface SegmentSubscriberSelectBoxProps {
    searchableSegment: string;
    setSearchableSegment: Dispatch<SetStateAction<string>>;
}
// Axios instance

// Fetch segments function
const fetchSegments = async (): Promise<Segment[]> => {
    const response = await API.get<{ success: boolean; data: Segment[] }>('/segments');
    if (response.data.success) {
        return response.data.data;
    } else {
        throw new Error('Failed to fetch segments');
    }
};

const SegmentSubscriberSelectBox: React.FC<SegmentSubscriberSelectBoxProps> = ({
    searchableSegment,
    setSearchableSegment,
}) => {
    const { data: segments, isLoading, error } = useQuery<Segment[]>(['segments'], fetchSegments);

    if (isLoading) {
        return <div className="h-full flex justify-center items-center">Loading...</div>;
    }

    if (error instanceof Error) {
        return (
            <div className="h-full flex justify-center items-center text-red-500">
                Error fetching segments: {error.message}
            </div>
        );
    }

    return (
        <div className="h-full flex justify-center items-center">
            <Select
                id="segmentSelect"
                required
                className="w-full h-full"
                value={searchableSegment}
                onChange={(e) => setSearchableSegment(e.target.value)}
            >
                <option value="" disabled>
                    Select a segment
                </option>
                <option key={"None"} value={"None"}>
                        None
                </option>
                {segments?.map((segment) => (
                    <option key={segment.ROWID} value={segment.ROWID}>
                        {segment.segmentName}
                    </option>
                ))}
            </Select>
        </div>
    );
};

export default SegmentSubscriberSelectBox;
