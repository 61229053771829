import {FileInput, Label, Button, Modal, Alert} from "flowbite-react";
import {ParseResult, parse} from "papaparse";
import {useEffect, useState} from "react";
import {FiFilePlus, FiLoader} from "react-icons/fi";
import {
    UserData,
    useAddSubscribers,
} from "../../pages/Subscriber/hooks/useAddSubscribers";
import {useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {HiInformationCircle} from "react-icons/hi";
import toast from "react-hot-toast";

export default function SubscriberFileUploadComponent() {
    const [openModal, setOpenModal] = useState(false);
    const [fileError, setFileError] = useState("");
    const [csvData, setCsvData] = useState<UserData[] | null>(null);
    const {prodId} = useParams();
    const queryClient = useQueryClient();

    const onUserAddSuccess = () => {
        queryClient.invalidateQueries(["pendingSubscribers", prodId]);
        queryClient.invalidateQueries(["subscribers", prodId]);

        setCsvData(null);
        toast.success("Subscribers have been added successfully");
    };

    const onUserAddFailure = () => {
        toast.error("Unable to add subscribers");
    };
    const {mutate, isLoading, error} = useAddSubscribers(
        onUserAddSuccess,
        onUserAddFailure
    );

    const [errorDisplay, setErrorDisplay] = useState<string | null>(null);

    const handleFileUpload = (file: File | undefined) => {
        if (file) {
            console.log(file.name);
            // if (file.type === "text/csv" || file.name.endsWith(".csv")) {
            //     console.log("file")
            //     parse(file, {
            //         complete: (result: ParseResult<string[]>) => {
                        
            //             if (result.data[0].length !== 2) {
            //                 console.log("result.data",result.data);
            //                 setFileError(
            //                     "Are you sure the CSV file is correct? It must start with 'Name' in the first column and 'Email' in the second from the first row, no column names needed."
            //                 );
            //                 setCsvData(null);
            //             } else {
            //                 const userData = result.data.map((item: string[]) => {
            //                     return {
            //                         name: item[0],
            //                         email: item[1].trim(),
            //                     };
            //                 });
            //                 setCsvData(userData);
            //                 setFileError("");
            //             }
            //         },
            //         header: false,
            //         skipEmptyLines: true,
            //     });

            if (file.type === "text/csv" || file.name.endsWith(".csv")) {
                console.log("file");
                parse(file, {
                    complete: (result: ParseResult<{ [key: string]: string }>) => {
                        const expectedHeaders = ["Name", "Email", "Tags", "Affiliation", "Designation"];
                        console.log({"result":result.data})
                        const actualHeaders = Object.keys(result.data[0] || {});
            
                        // Validate headers
                        if (!expectedHeaders.every(header => actualHeaders.includes(header))) {
                            setFileError(
                                `The CSV file must have the following headers: ${expectedHeaders.join(
                                    ", "
                                )}. Ensure that all required columns are present.`
                            );
                            setCsvData(null);
                            return;
                        }
            
                        const userData = result.data.map((row: { [key: string]: string }) => ({
                            name: row.Name || "", // Default to an empty string if missing
                            email: row.Email?.trim() || "", // Ensure email is trimmed and not null
                            tags: row.Tags || "", // Optional field
                            affiliation: row.Affiliation || "", // Optional field
                            designation: row.Designation || "", // Optional field
                        }));
            
                        setCsvData(userData);
                        setFileError(""); // Clear any previous errors
                    },
                    header: true, // Enable header mode
                    skipEmptyLines: true, // Skip empty rows in the CSV
                });
            
            
            } else {
                setFileError("Invalid file type. Please upload a CSV file.");
                setCsvData(null);
            }
        }
    };

    const handleSubmit = () => {
        if (prodId) {
            mutate({productId: prodId, subscribers: csvData ?? []});
        }
    };

    useEffect(() => {
        if (error) {
            setErrorDisplay(
                "Unable to add subscribers. Please check the email addresses and try again."
            );
        }
    }, [error]);


    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const handleDragOver = (e: any) => {
        e.preventDefault(); // Prevent default behavior to allow drop
        if (!isDraggingOver) setIsDraggingOver(true);
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (e: any) => {
        e.preventDefault();
        setIsDraggingOver(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setIsDraggingOver(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileUpload(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };


    return (
        <>
            <Button color="blue" onClick={() => setOpenModal(true)}>
                <div className="flex align-center justify-center space-x-2">
                    <FiFilePlus className="h-5 w-5"/>
                    <span>Bulk Add</span>
                </div>
            </Button>
            <Modal
                dismissible={!isLoading}
                show={openModal}
                onClose={() => {
                    // Only allow closing the modal if we are not in process of bulk addition
                    if (!isLoading) {
                        setOpenModal(false);
                    }
                }}
            >
                <Modal.Header>Bulk Addition</Modal.Header>
                <Modal.Body className="p-0">
                    {!csvData && (
                        <div className="m-6">
                            {fileError ? (
                                <Alert color="failure" className="mb-2">
                                    {fileError}
                                </Alert>
                            ) : (
                                <p className="mb-2 text-gray-600">
                                    Please upload a CSV file with the  columns: Name , Email, Tags, Affiliation and Designation.
                                </p>
                            )}
                            <div className="flex w-full items-center justify-center">
                                <label
                                    htmlFor="dropzone-file"
                                    className={`flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed ${isDraggingOver ? 'border-gray-500 bg-gray-300' : 'border-gray-300 bg-gray-50'} hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
                                    onDragOver={handleDragOver}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                        <svg
                                            className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">Click to upload</span> or
                                            drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            CSV File
                                        </p>
                                    </div>
                                    <FileInput
                                        id="dropzone-file"
                                        className="hidden"
                                        onChange={(e) => {
                                            const file = e.target.files?.[0]
                                            handleFileUpload(file);
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    )}
                    {csvData && (
                        <div className="my-4 mx-2">
                            {errorDisplay ? (
                                <Alert
                                    color="failure"
                                    icon={HiInformationCircle}
                                    className="m-2"
                                >
                                    <span className="font-medium">Error!</span> {errorDisplay}
                                </Alert>
                            ) : (
                                csvData.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex justify-between items-center p-2 border-b border-gray-200"
                                        >
                                            <div className="flex space-x-2 items-center">
                                                <div
                                                    className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 text-gray-500">
                                                    {index + 1}
                                                </div>
                                                <div>
                                                    <div className="text-gray-800 font-medium">
                                                        {item.name}
                                                    </div>
                                                    <div className="text-gray-500">{item.email}</div>
                                                </div>
                                            </div>
                                            <div className="flex space-x-2">
                                                <button
                                                    disabled={isLoading}
                                                    onClick={() => {
                                                        if (isLoading) return;

                                                        setCsvData((prev) => {
                                                            if (prev) {
                                                                return prev.filter((_, i) => i !== index);
                                                            }
                                                            return null;
                                                        });
                                                    }}
                                                    className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 text-gray-500"
                                                >
                                                    {"✖️"}
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {csvData && (
                        <>
                            <Button
                                color="success"
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                {isLoading ? (
                                    <FiLoader className="my-auto h-4 w-4 animate-spin"/>
                                ) : (
                                    "Add"
                                )}
                            </Button>
                            <Button
                                disabled={isLoading}
                                color="blue"
                                onClick={() => {
                                    setErrorDisplay(null);
                                    setCsvData(null);
                                }}
                            >
                                Reset
                            </Button>
                        </>
                    )}
                    <Button
                        disabled={isLoading}
                        color="failure"
                        onClick={() => setOpenModal(false)}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
