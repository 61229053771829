import { Pagination, Select, TextInput, Dropdown, Label } from "flowbite-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import SubscriberTable from "../../components/Subscriber/SubscriberTable";
import { HiOutlineExclamationCircle, HiPlusCircle } from "react-icons/hi";
import { Button, Modal, Toast, Tooltip } from "flowbite-react";
import useDeleteSubscriber from "./hooks/useDeleteSubscriber";
import { FiLoader } from "react-icons/fi";
import { CiMenuKebab } from "react-icons/ci";
import TableSkeletonLoader from "../../components/Shared/TableSkeletonLoader";
import { useEffect, useState } from "react";
import EditSubscriberModal from '../../components/Subscriber/SubscriberEditModel'
import DownloadCSV from "./downloadCSV";
import { SegmentModal } from "./segmentModal";
import SegmentSubscriberSelectBox from '../../components/Subscriber/SegmentSubscriberSelectBox';
import useUpdateSubscriber from "./hooks/useUpdateSubscriber";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";

const { useSubscribers } = require("./hooks/useSubscribers");

export default function SubscriberList() {
  const { prodId, orgId } = useParams();
  const [searchableSegment, setSearchableSegment] = useState<string>('')
  const [segmentId, setSegmentId] = useState<string>('');
  const navigate = useNavigate();
  const [attribute, setAttribute] = useState<string>('NONE'); // State for selected attribute
  const [searchText, setSearchText] = useState<string>(''); // State for input text
  const [searchtag, setSearchTag] = useState<string | undefined>('');
  const [searchvalue, setSearchValue] = useState<string | undefined>('');
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<boolean>(false);
  const { getSubscribersForProductPagebypage, getTotalPagesForProduct } = useSubscribers();
  const { isModalOpen, closeModal, openModal, deleteSubscriber, isDeleting, } =
    useDeleteSubscriber();
  const { isModalOpen: isUpdateModalOpen, closeModal: closeUpdateModal, openModal: openUpdateModal, isUpdating, subscriberID, toast, setToast } = useUpdateSubscriber()

  const queryClient = useQueryClient();

  useEffect(() => { }, [toast])
  const handleSearch = () => {
    setSearchTag(attribute);
    setSearchValue(searchText);
    console.log(`Searching for ${searchText} in ${attribute}`);
    if (attribute === "NONE") {
      setSearchText("");
    }
  };

  const handleSegmentSearch = () => {
    console.log({ searchableSegment });
    setPage(1);
    setSegmentId(searchableSegment);
  };


  const { data: subscribers, isLoading: loading } = useQuery({
    queryKey: ["subscribers", prodId, page, sort, searchtag, searchvalue, segmentId],
    queryFn: () => getSubscribersForProductPagebypage(prodId, page, sort, searchtag, searchvalue, segmentId),
    staleTime: 1000 * 60,
  });

  const { data: totalPages, isLoading: pagesLoading } = useQuery({
    queryKey: ["totalpages", prodId, searchtag, searchvalue, segmentId],
    queryFn: () => getTotalPagesForProduct(prodId as string, searchtag, searchvalue, segmentId),
    staleTime: 1000 * 60,
  })


  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(["subscribers", prodId]);
  };

  const onDeleteFailure = (error: string) => {
    console.log(error);
  };

  return (
    <div className="mx-1 my-2 bg-white p-6 rounded-lg h-full">
      <div className="flex flex-col">
        <div className="flex-1">
          <h1 className="text-gray-800 text-2xl font-semibold mb-4">
            Subscribers
          </h1>

        </div>
        <div>
          <div className="flex gap-2 justify-between">
            {/* custom attribute based filtering */}
            <div className="flex gap-2 h-full">
              <SegmentModal />
              <SegmentSubscriberSelectBox
                searchableSegment={searchableSegment}
                setSearchableSegment={setSearchableSegment} />
              <Button  color="blue" onClick={
                () => handleSegmentSearch()
              }>Search</Button>
            </div>

            <div className="flex gap-2 h-full justify-end items-end mb-2">
              <div className="relative -top-4 cursor-pointer">
                <Dropdown label="" dismissOnClick={false} renderTrigger={() => <CiMenuKebab />} className="absolute">
                  <Dropdown.Item onClick={() => { setSort(!sort) }}>{sort ? "Latest" : "Oldest"}</Dropdown.Item>
                  <Dropdown.Item>
                    <DownloadCSV
                      productID={prodId || ""}
                      queryParams={{
                        sort: sort ? 1 : 0,
                        page: page.toString(),
                        searchtag,
                        searchvalue,

                      }}
                    />
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <Button
                color="blue"
                onClick={() =>
                  navigate(`/app/${orgId}/notes/${prodId}/subscribers/add`)
                }
              >
                <div className="flex space-x-1 align-center justify-center">
                  <HiPlusCircle className="h-5 w-5" />
                  <span>Add Subscribers</span>
                </div>
              </Button>
              <Tooltip content="total pages">
              <Button color="white" outline>
                {totalPages?1:totalPages}
              </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        {loading ? (
          <TableSkeletonLoader />
        ) : (
          <div>
            <SubscriberTable
              openDeleteModal={openModal}
              openUpdateModal={openUpdateModal}
              subscribers={subscribers}

            />

            {isModalOpen && (
              <Modal show={isModalOpen} onClose={closeModal}>
                <Modal.Body>
                  <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to delete this product?
                    </h3>
                    <div className="flex justify-center gap-4">
                      <Button
                        color="failure"
                        className="w-36"
                        onClick={() => {
                          deleteSubscriber(onDeleteSuccess, onDeleteFailure);
                        }}
                      >
                        {isDeleting ? (
                          <FiLoader className="my-auto h-4 w-4 animate-spin" />
                        ) : (
                          "Yes, I'm sure"
                        )}
                      </Button>
                      <Button color="gray" onClick={closeModal}>
                        No, cancel
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}

            {isUpdateModalOpen && (
              <EditSubscriberModal
                openModal={isUpdateModalOpen}
                closeOpenModel={closeUpdateModal}
                subscriberID={subscriberID}
              />
            )}
            {pagesLoading ? <></> : <div className="flex ml-auto mt-2 justify-end">
              <Pagination
                currentPage={page}
                totalPages={parseInt(totalPages)}
                onPageChange={(nextPage) => {
                  setPage(nextPage);
                }
                }

              />
              
            </div>}

            {/* Toast Notification */}
            {toast.show && (
              <div className="fixed bottom-5 right-5">
                <Toast>
                  {toast.type === "success" ? (
                    <HiCheckCircle className="h-5 w-5 text-green-500" />
                  ) : (
                    <HiExclamationCircle className="h-5 w-5 text-red-500" />
                  )}
                  <div className="ml-3 text-sm font-normal">{toast.message}</div>
                  <button
                    className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg p-1.5"
                    onClick={() => setToast({ ...toast, show: false })}
                  >
                    <span className="sr-only">Close</span>
                    &times;
                  </button>
                </Toast>
              </div>
            )}

          </div>

        )}
      </div>
    </div>
  );
}
