import API from "../../../api";


export type Subscriber = {
  ROWID: string;
  Product: string;
  Email: string;
  Name: string;
  CREATEDTIME: string;
  Affiliation: string;
  Tags: string;
  Designation: string;
};

export const useSubscribers = () => {

  return {
    getSubscribersForProduct: async (prodId: string): Promise<Subscriber[]> => {
      try {
        const response = await API.get(`/subscribers/${prodId}`);
        return response.data.payload;
      } catch (err) {
        
        throw err;
      }
    },

    getSubscribersForProductPagebypage: async (prodId: string, page: number, sort: boolean, searchtag: string, searchvalue: string,segmentId:string): Promise<Subscriber[]> => {
     
      try {
        console.log({page});
        if(segmentId && segmentId!=='None'){
          const response = await API.post(`/execute/segment/${segmentId}`,{
            productId: prodId,
            page:page,
            sort
          });
          
          return response.data.data;
        }
        
        if (searchtag === "NONE" || searchtag === "" || !searchtag) {
          const response = await API.get(`/subscribers/page/${prodId}?page=${page}&sort=${sort ? 1 : 0}`);
          return response.data.payload;
        } else {
          
          const response = await API.get(`/subscribers/page/${prodId}?page=${page}&sort=${sort ? 1 : 0}&searchtag=${searchtag}&searchvalue=${searchvalue}&pagesize=100`)
          return response.data.payload;
        }

      } catch (err) {
        
        throw err;
      }
    },

    getPendingSubscribersForProduct: async (
      prodId: string
    ): Promise<Subscriber[]> => {
      try {
        const response = await API.get(`/subscribers/${prodId}/pending`);
        return response.data.payload;
      } catch (err) {
        
        throw err;
      }
    },
    getTotalPagesForProduct: async (prodId: string,searchtag:string,searchvalue:string,segmentId?:string): Promise<number> => {
      try {
        if(segmentId && segmentId!=='' && segmentId!=='None'){
          const response = await API.post(`/execute/segment/noofpages/${segmentId}`,{
            productId: prodId
          });
          const totalPages = response.data.total_pages;
          
          return totalPages;
        }

        const response = await API.get(`/subscribers/noofpages/${prodId}?searchtag=${searchtag}&searchvalue=${searchvalue}`);
        const totalPages = response.data.total_pages;
        
        return totalPages;

      } catch (err) {
        
        throw err;
      }
    },
  };
};
