import { Table } from "flowbite-react";
import { Subscriber } from "../../pages/Subscriber/hooks/useSubscribers";
import { HiTrash } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import EditSubscriberModal from '../../components/Subscriber/SubscriberEditModel'
interface SubscriberTableProps {
  subscribers: Subscriber[];
  openDeleteModal: (subscriberID: string) => void;
  openUpdateModal:(subscriberID: string)=>void;
}

function SubscriberTable({
  subscribers,
  openDeleteModal,
  openUpdateModal
}: SubscriberTableProps) {
  return (
    <div>
      <Table hoverable>
        <Table.Head>
          <Table.HeadCell className="bg-gray-200">ID</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">User Email</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">User Name</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Added Date</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Tags</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Designation</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Affiliation</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {subscribers?.map((subscriber) => (
            <Table.Row
              key={subscriber.ROWID}
              className="bg-gray-50 dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell>{subscriber.ROWID}</Table.Cell>
              <Table.Cell>{subscriber.Email}</Table.Cell>
              <Table.Cell>{subscriber.Name ?? "--"}</Table.Cell>
              <Table.Cell>{subscriber.CREATEDTIME}</Table.Cell>
              <Table.Cell>{subscriber.Tags ?? "--"}</Table.Cell>
              <Table.Cell>{subscriber.Designation ?? "--"}</Table.Cell>
              <Table.Cell>{subscriber.Affiliation ?? "--"}</Table.Cell>
              <Table.Cell className="flex space-x-1">
                <div className="flex-1"></div>
                <div className="flex space-x-1">
                  <div className="relative group">
                    <button
                      onClick={() => openDeleteModal(subscriber.ROWID)}
                      className="text-red-700 rounded-sm focus:outline-none"
                    >
                      <HiTrash className="h-5 w-5" />
                      <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                        Delete
                      </span>
                    </button>
                  </div>
                  {/* edit button */}
                  <div className="relative group">
                    <button
                      onClick={() =>openUpdateModal(subscriber.ROWID)}
                      className="text-yellow-700 rounded-sm focus:outline-none"
                    >
                      <FaRegEdit className="h-5 w-5" />
                      <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                        Edit
                      </span>
                    </button>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default SubscriberTable;
