import { useMutation, UseMutationResult } from "@tanstack/react-query";
import API from "../../../api";

export interface UserData {
  name: string;
  email: string;
  [key: string]: string;
}

type AddSubscribersParams = {
  productId: string;
  subscribers: UserData[];
};

export function useAddSubscribers(
  onSuccess?: () => void,
  onError?: (error: unknown) => void
): UseMutationResult<void, unknown, AddSubscribersParams> {
  return useMutation<void, unknown, AddSubscribersParams>(
    async ({ productId, subscribers }) => {
      const url = `/private/products/${productId}/subscribers/bulkadd`;
      console.log({subscribers});
      await API.post(url, subscribers);
    },
    {
      onSuccess,
      onError,
    }
  );
}
