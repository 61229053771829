import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Radio, Modal, Checkbox, Label, TextInput, Datepicker } from "flowbite-react";
import { useState } from "react";
import { fetchCheckboxState, updateCheckboxState, useAddSegmentMutation } from './hooks/useCheckboxState'
import CustomDatepicker from "../../components/Datepicker/CustomDatepicker";

export function SegmentModal(): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  const [inputValues, setInputValues] = useState<Record<string,string|any>>({
  "name": null,
  "segmentName": null,
  "creator": null,
  "createdDate": null,
  "emailEndsWith": null,
  "tags": null,
  "affiliation": null,
  "designation": null
  });
  const [segmentName, setSegmentName] = useState<string>('');
  const queryClient = useQueryClient();



  const [radioStates, setRadioStates] = useState({
    emaillogic: "None",
    tagslogic: "None",
    affiliationlogic: "None",
    designationlogic:"None",
  });

  const handleRadioChange = (group: string, value: string) => {
    console.log({ radioStates });
    setRadioStates((prevState) => ({
      ...prevState,
      [group]: value,
    }));
  };

  const handleInputChange = (id: string, value: string) => {
    console.log({ inputValues });
    setInputValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };


  // Fetch initial state
  const { data: checkboxState = {}, isLoading } = useQuery({
    queryKey: ["checkboxState"],
    queryFn: fetchCheckboxState,
  });

  // Update state mutation
  const mutation = useMutation(updateCheckboxState, {
    onSuccess: (data) => {
      queryClient.setQueryData(["checkboxState"], data);
    },
  });

  const handleCheckboxChange = (key: string) => {
    const updatedState = { ...checkboxState, [key]: !checkboxState[key] };
    mutation.mutate(updatedState);
  };


  const addSegmentMutation = useAddSegmentMutation(
    () => {
      queryClient.invalidateQueries(["segments"]); // Invalidate related queries to fetch updated data
      setOpenModal(false); // Close the modal after success
    },
    (error) => {
      console.error("Error adding segment:", error);
      alert("Failed to add segment. Please try again.");
    }
  );

  const handleSave = () => {
    const segmentData = {
      ...inputValues,
      ...radioStates,
      segmentName
    };
    console.log({segmentData});
    addSegmentMutation.mutate({segment:segmentData});
  };


  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Button  color="blue" onClick={() => setOpenModal(true)}>Create Segment</Button>
      <Modal show={openModal} onClose={() => setOpenModal(false)} size="2xl">
        <Modal.Header>Create Filter</Modal.Header>
        <Modal.Body>
          <form className="grid grid-cols-12 gap-2">
            <CheckboxWithInput
              id="name"
              label="Name"
              placeholder="Username goes here"
              inputType="text"
              state={checkboxState}
              value={inputValues["name"] || ""}
              onCheckboxChange={handleCheckboxChange}
              onInputChange={handleInputChange}
            />
            <CheckboxWithInput
              id="creator"
              label="Created By"
              placeholder="Creator name"
              inputType="text"
              state={{"creator":false}}
              onCheckboxChange={handleCheckboxChange}
              value={inputValues["creator"] || ""}
              onInputChange={handleInputChange}
            />
            <CheckboxWithInput
              id="createdDate"
              label="Created Date"
              isDatepicker
              state={checkboxState}
              onCheckboxChange={handleCheckboxChange}
              value={inputValues["createdDate"]||''}
              onInputChange={handleInputChange}

            />
             <div className="col-span-6 flex flex-col justify-around">
              <div></div>
              <RadioGroup
                group="emaillogic"
                enable={checkboxState['createdDate'] ||checkboxState['name'] || checkboxState['creator'] }
                selectedValue={radioStates.emaillogic}
                onChange={handleRadioChange}
              />
            </div>
            <CheckboxWithInput
              id="emailEndsWith"
              label="email containing"
              placeholder="email ends with"
              inputType="text"
              state={checkboxState}
              onCheckboxChange={handleCheckboxChange}
              value={inputValues["emailEndsWith"] || ""}
              onInputChange={handleInputChange}
            />

             <div className="col-span-6 row-span-1 h-full flex flex-col justify-around">
              <div></div>
              <RadioGroup
                group="tagslogic"
                selectedValue={radioStates.tagslogic}
                enable={checkboxState["emailEndsWith"]}
                onChange={handleRadioChange}

              />
            </div>
           
            {/* Add other fields similarly */}
            <CheckboxWithInput
              id="tags"
              label="Tags containing"
              placeholder="Type tags"
              inputType="text"
              state={checkboxState}
              onCheckboxChange={handleCheckboxChange}
              value={inputValues["tags"] || ""}
              onInputChange={handleInputChange}
            />
            <div className="col-span-6 flex flex-col justify-around">
              <div></div>
              <RadioGroup
                group="affiliationlogic"
                selectedValue={radioStates.affiliationlogic}
                onChange={handleRadioChange}
                enable={checkboxState["emailEndsWith"]}
              />
            </div>

            <CheckboxWithInput
              id="affiliation"
              label="Affiliation containing"
              placeholder="Type affiliation"
              inputType="text"
              state={checkboxState}
              onCheckboxChange={handleCheckboxChange}
              value={inputValues["affiliation"] || ""}
              onInputChange={handleInputChange}
            />

          <div className="col-span-6 flex flex-col justify-around">
            <div></div>
              <RadioGroup
                group="designationlogic"
                enable={checkboxState["affiliation"]}
                selectedValue={radioStates.designationlogic}
                onChange={handleRadioChange}
              />
            </div>
           

            <CheckboxWithInput
              id="designation"
              label="Designation"
              placeholder="Type designation"
              inputType="text"
              state={checkboxState}
              onCheckboxChange={handleCheckboxChange}
              value={inputValues["designation"] || ""}
              onInputChange={handleInputChange}
            />


          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={() => setOpenModal(false)}>
            SAVE
          </Button> */}
          <SaveCustomerSegmentModal value={segmentName} onValueChange={setSegmentName} handleSave={handleSave} setModal={setOpenModal} />
          <Button  onClick={() => setOpenModal(false)}>
            Search
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
interface SaveCustomerSegmentModalProps{
  value?:string
  onValueChange:(key:string)=>void;
  handleSave:()=>void;
  setModal:(value:boolean)=>void;
}


function SaveCustomerSegmentModal({value,onValueChange,handleSave,setModal}:SaveCustomerSegmentModalProps): JSX.Element {
  const [openSegmentModal, setOpenSegmentModal] = useState(false);
  const handleSaveandExit=()=>{
    handleSave();
    setOpenSegmentModal(false);
    setModal(false);
  }
  return <>
    <Button color="blue" onClick={() => setOpenSegmentModal(true)}>
      SAVE
    </Button>
    <Modal show={openSegmentModal} onClose={() => setOpenSegmentModal(false)} size="2xl">
      <Modal.Header>SAVE</Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-6 gap-2">
          <div className="col-span-6">
            <Label htmlFor="segmentName" value={"Segment Name"} />
          </div>
          <div className="col-span-6">
            <TextInput
              id={"segmentName"}
              type="text"
              placeholder="Enter segment Name"
              value={value}
              onChange={(e) => onValueChange(e.target.value)}
            />
          </div>
          <div className="col-span-6">
            <Button color="blue" onClick={handleSaveandExit}>
              SAVE
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
}

interface CheckboxWithInputProps {
  id: string;
  label: string;
  placeholder?: string;
  inputType?: string;
  isDatepicker?: boolean;
  state: Record<string, boolean>;
  value: string;
  onCheckboxChange: (key: string) => void;
  onInputChange: (id: string, value: string) => void;
}


function CheckboxWithInput({
  id,
  label,
  placeholder = "",
  inputType = "text",
  isDatepicker = false,
  state,
  value,
  onCheckboxChange,
  onInputChange,
}: CheckboxWithInputProps): JSX.Element {
  return (
    <div className="col-span-6">
      <div className="mb-2 block">
        <Checkbox
          id={`enable-${id}`}
          checked={state[id]}
          onChange={() => onCheckboxChange(id)}
        />
        <Label htmlFor={id} value={label} />
      </div>
      {isDatepicker ? (
        <CustomDatepicker
          id={id}
          placeholder="Select a date"
          disabled={!state[id]} // Passes `disabled` functionality (add it in CustomDatepicker if needed)
          onDateChange={(value) => onInputChange(id, value)} // Handles the selected date
        />
      ) : (
        <TextInput
          id={id}
          type={inputType}
          placeholder={placeholder}
          disabled={!state[id]}
          value={value}
          onChange={(e) => onInputChange(id, e.target.value)}
        />
      )}
    </div>
  );
}

interface RadioGroupProps {
  group: string;
  enable:boolean;
  selectedValue: string;
  onChange: (group: string, value: string) => void;
}

function RadioGroup({ group, enable, selectedValue, onChange }: RadioGroupProps) {
  return (
    <div className="flex gap-2 items-center">
      <div className="flex items-center gap-2">
        <Radio
          id={`${group}-and`}
          name={group}
          value="AND"
          checked={selectedValue === "AND"}
          onChange={() => onChange(group, "AND")}
          disabled={!enable}
        />
        <Label htmlFor={`${group}-and`}>AND</Label>
      </div>
      <div className="flex items-center gap-2">
        <Radio
          id={`${group}-or`}
          name={group}
          value="OR"
          checked={selectedValue === "OR"}
          onChange={() => onChange(group, "OR")}
          disabled={!enable}
        />
        <Label htmlFor={`${group}-or`}>OR</Label>
      </div>
      <div className="flex items-center gap-2">
        <Radio
          id={`${group}-none`}
          name={group}
          value="None"
          checked={selectedValue === "None"}
          onChange={() => onChange(group, "None")}
          disabled={!enable}
        />
        <Label htmlFor={`${group}-none`}>None</Label>
      </div>
    </div>
  );
}