import { useQuery, useMutation, useQueryClient, UseMutationResult  } from "@tanstack/react-query";
import { useState } from "react";
import API from "../../../api";

// Mock API calls
const fetchCheckboxState = async (): Promise<Record<string, boolean>> => {
    return {
      name: false,
      creator: false,
      createdDate: false,
      emailEndsWith: false,
      tags: false,
      affiliation: false,
      designation: false,
    };
};

const updateCheckboxState = async (updatedState: Record<string, boolean>) => {
    console.log("Saving to server:", updatedState);
    return updatedState;
};

const addSegment = async (segmentData: Record<string, any>) => {
  const response = await API.post("/public/segments/add", segmentData);
  return response.data;
};


export interface  SegmentModal {
  CREATORID?: string
  emailEndsWith?: string|any
  affiliationlogic?: string|any
  creator?: string| any
  emaillogic?: string | any
  segmentName?: string
  tags?: string| any
  MODIFIEDTIME?: string
  createdDate?: string| any
  affiliation?: string| any
  name?: string | any
  CREATEDTIME?: string
  designation?: any
  tagslogic?: string
  ROWID?: string
}

type AddSegmentParams={
  segment:SegmentModal
}



 function useAddSegmentMutation(
  onSuccess?:()=>void,
  onError?:(error:unknown)=>void
):UseMutationResult<void,unknown,AddSegmentParams>{
  return useMutation<void,unknown,AddSegmentParams>(
    async ({segment})=>{
      console.log({segment});
      const url =  `/public/segments/add`;
      await API.post(url,segment);
    },{
      onSuccess,
      onError
    }
  )

  
}

export {fetchCheckboxState,updateCheckboxState,addSegment,useAddSegmentMutation}
