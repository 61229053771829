import { Button, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import {useSendTestEmailToSegment} from "../../../../pages/Products/hooks/useProducts";
import { toast } from "react-toastify";

import SegmentSubscriberSelectBox from "../../../Subscriber/SegmentSubscriberSelectBox";

export default function PublishModalSendSegmentComponent({
    releaseNoteID,
    isLoading,
    setIsLoading,
}: {
    releaseNoteID: string;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
}) {
   
    const [searchableSegment, setSearchableSegment] = useState<string>('')
    const {sendSegmentEmail, isSending, error } = useSendTestEmailToSegment(onSuccess, onError);
    function onSuccess() {
        setSearchableSegment("");
        toast.success("Email sent");
        setIsLoading(false);
        
    }

    function onError() {
        setSearchableSegment("");
        toast.error("Unable to send Email");
        setIsLoading(false);
    }

    function handleSendTestEmail() {
        setIsLoading(true);
        sendSegmentEmail({
            segmentID: searchableSegment,
            noteID: releaseNoteID,
        });
    }

    if (error) {
        console.log(error);
        return (
            <div>
                <div className="text-red-500 text-sm">{`${error}`}</div>
            </div>
        );
    }

    return (
        <>
            <div className="">
                <form
                    className="flex space-x-6"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (e.currentTarget.checkValidity()) {
                            handleSendTestEmail();
                        }
                    }}
                >
                    <div className="flex-1 flex flex-col space-y-2">
                        <Label htmlFor="testEmail">Send Test Email</Label>
                        <p className="text-sm text-gray-600">
                            Use this option to send the release note to a specific subscriber segment
                           
                        </p>
                        
                        <SegmentSubscriberSelectBox
                            searchableSegment={searchableSegment}
                            setSearchableSegment={setSearchableSegment} />
                    </div>
                    <div className="mt-auto">
                        <Button
                            type="submit"
                            color="dark"
                            isProcessing={isSending}
                            disabled={isLoading || isSending}
                        >
                            <div className="w-32">Send Test Email</div>
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}
