import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { HiFolderOpen, HiOutlineClock, HiPuzzle } from "react-icons/hi";
import { useGetDashboardData } from "../../hooks/dashboard/useDashboardData";
import { useParams } from "react-router-dom";
import { DashboardPayload } from "../../types/dashboard";
import GenericSkeletonLoader from "../../components/Shared/GenericSkeletonLoadingPage";

const DashboardPage = () => {
  const { prodId } = useParams<{ prodId: string }>();

  const { data, isError, isLoading } = useGetDashboardData({
    productId: prodId ?? "",
  });

  if (isError) {
    return <div>Error</div>;
  }
  console.log({ isLoading });
  if (isLoading) {
    return <GenericSkeletonLoader />;
  }

  const payload = data as DashboardPayload;

  const { verified, unverified } = payload.subscribers;
  const { positive, negative } = payload.feedback;

  const releaseNotesData = Object.values(payload.releaseNotes.releaseNotes);

  const transformedSubscriberGrowthData = Object.entries(
    payload.subscriberGrowth
  ).map(([date, count]) => ({
    date: new Date(date),
    subscribers: count,
  }));

  const feedbackGrowthData = Object.entries(payload.feedbackGrowth).map(
    ([key, count]) => ({
      week: key.split("_")[0].slice(1) + " Week " + key.split("W")[1],
      count: count,
    })
  );

  return (
    <div className="p-4 space-y-4">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 shadow rounded flex items-center">
          <HiOutlineClock className="text-xl mr-2" />
          <div>
            <h2 className="font-bold text-lg">Verified Subscribers</h2>
            <p className="text-xl">{verified}</p>
          </div>
        </div>
        <div className="bg-white p-4 shadow rounded flex items-center">
          <HiOutlineClock className="text-xl mr-2" />
          <div>
            <h2 className="font-bold text-lg">Unverified Subscribers</h2>
            <p className="text-xl">{unverified}</p>
          </div>
        </div>
        <div className="bg-white p-4 shadow rounded flex items-center">
          <HiFolderOpen className="text-xl mr-2" />
          <div>
            <h2 className="font-bold text-lg">Positive Feedback</h2>
            <p className="text-xl">{positive}</p>
          </div>
        </div>
        <div className="bg-white p-4 shadow rounded flex items-center">
          <HiPuzzle className="text-xl mr-2" />
          <div>
            <h2 className="font-bold text-lg">Negative Feedback</h2>
            <p className="text-xl">{negative}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 shadow rounded">
          <h2 className="font-bold text-lg">Release Notes (Last 10 Weeks)</h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={releaseNotesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-4 shadow rounded">
          <h2 className="font-bold text-lg">
            Subscriber Growth (Last 30 Days)
          </h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={transformedSubscriberGrowthData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) =>
                  Intl.DateTimeFormat("en-US").format(value)
                }
              />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="subscribers"
                stroke="#82ca9d"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="bg-white p-4 shadow rounded mt-4">
        <h2 className="font-bold text-lg mb-4">Feedback</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={feedbackGrowthData} margin={{ top: 20 }}>
            <CartesianGrid horizontal={false} vertical={false} />
            <XAxis dataKey="week" />
            <YAxis allowDecimals={false} domain={["dataMin", "auto"]} />
            <Tooltip />
            <Bar
              dataKey="count"
              fill="#8884d8"
              barSize={40}
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DashboardPage;
