import React from 'react';
import API from '../../api';

interface DownloadCSVProps {
    productID: string; // Product ID required as a prop
    queryParams?: {
        page:string;
        sort: number;
        searchtag?: string;
        searchvalue?: string;
        pagesize?: string;
    };
}

const DownloadCSV: React.FC<DownloadCSVProps> = ({ productID, queryParams }) => {
    const buildEndpointWithQueryParams = (baseEndpoint: string, params?: Record<string, any>) => {
        if (!params) return baseEndpoint;

        const queryString = Object.keys(params)
            .filter((key) => params[key] !== undefined && params[key] !== null) // Only include defined values
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');

        return queryString ? `${baseEndpoint}?${queryString}` : baseEndpoint;
    };

    const downloadCSV = async () => {
        try {
            // Construct the endpoint with query parameters
            const endpoint = buildEndpointWithQueryParams(`/subscribers/csv/${productID}`, queryParams);

            // Make a GET request to fetch the CSV file
            const response = await API.get(endpoint, {
                responseType: 'blob', // Expecting a file blob as the response
            });

            // Create a URL for the file blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary link element for downloading
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `subscribers_${productID}.csv`); // File name includes productID

            // Append the link, trigger the download, and remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    return (

        <span onClick={downloadCSV}>Download CSV</span>

    );
};

export default DownloadCSV;
